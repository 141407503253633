export default function optimizeImg(
	url?: string,
	options?: {
		protocol?: 'http' | 'https';
		output?: 'jpg' | 'png' | 'gif' | 'tiff' | 'webp' | 'json';
		height?: number;
		width?: number;
		fit?: 'contain' | 'fill' | 'cover' | 'outside' | 'inside';
		maxAge?: {
			period: 'd' | 'w' | 'M' | 'y';
			time: number;
		};
		default?: string;
		// only for png
		compressionLevel?: number;
		// only for jpg, tiff or webp
		quality?: number;
	},
): string {
	let qp = '';
	let finalUrl = url;
	if (!finalUrl && options?.default) {
		finalUrl = options.default;
	}
	if (options?.output) {
		qp = qp + getQpString('output', options.output);
	}
	if (options?.width) {
		qp = qp + getQpString('w', options.width.toString());
	}
	if (options?.height) {
		qp = qp + getQpString('h', options.height.toString());
	}
	if (options?.fit) {
		qp = qp + getQpString('fit', options.fit);
	}
	if (options?.maxAge) {
		qp =
			qp +
			getQpString(
				'maxage',
				`${options.maxAge.time}${options.maxAge.period}`,
			);
	}
	if (options?.compressionLevel) {
		qp = qp + getQpString('l', options.compressionLevel.toString());
	}
	if (options?.quality) {
		qp = qp + getQpString('q', options.quality.toString());
	}
	return `${options?.protocol || 'https'}://wsrv.nl/?url=${finalUrl}${qp}`;
}

function getQpString(pmName: string, value: string) {
	return `&${pmName}=${value}`;
}
