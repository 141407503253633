const imageNext = '/svg/swipe_next.svg';
const imagePrev = '/svg/swipe_previous.svg';

let style = {
	'.swiper-button-next': {
		bgImage: imageNext,
		bgRepeat: 'no-repeat',
		'&:after': {
			content: 'unset',
		},
	},
	'.swiper-button-prev': {
		bgImage: imagePrev,
		bgRepeat: 'no-repeat',
		'&:after': {
			content: 'unset',
		},
	},

	'.swiper-slide': {
		height: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},

	'&.swiper-thumbs': {
		height: '20%',
		boxSizing: 'border-box',
		marginTop: 3,
	},

	'&.swiper-thumbs .swiper-slide': {
		width: '25%',
		height: '100%',
		opacity: '0.4',
	},

	'&.swiper-thumbs .swiper-slide-thumb-active': {
		opacity: '1',
	},

	'&.swiper-thumbs img': {
		height: '100%',
		boxSizing: 'border-box',
		width: '100%',
		objectFit: 'cover',
	},
};

export default style;
