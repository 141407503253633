import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper/types';
import IBaseProps from '../../types/interfaces/IBaseProps';
import style from './Carousel.style';
import { chakra, useBreakpointValue } from '@chakra-ui/react';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
const CSwiper = chakra(Swiper);

interface IProps extends IBaseProps {
	maxSlides?: number;
	slidesPerView?: {
		base?: number;
		sm?: number;
		md?: number;
		lg?: number;
		xl?: number;
	};
	spaceBetween?: {
		base?: number;
		sm?: number;
		md?: number;
		lg?: number;
		xl?: number;
	};
	withThumbs?: boolean;
	freeMode?: boolean;
	navigation?: 'navigation' | 'pagination' | 'none';
}

function Carousel(props: IProps) {
	const [thumbsSwiper, setThumbsSwiper] = useState<ISwiper | undefined>(
		undefined,
	);

	function getSlides() {
		let slides = React.Children.map(props.children, slide => (
			<SwiperSlide>{slide}</SwiperSlide>
		));

		// if (slides && props.maxSlides) {
		// 	// Move last element in the position of the last slide, if maxSlides is setted
		// 	slides[props.maxSlides - 1] = slides[slides?.length - 1];
		// }
		return slides?.slice(0, props.maxSlides);
	}

	const slidesPerView = useBreakpointValue(
		props.slidesPerView || { base: 2.4 },
	);
	const spaceBetween = useBreakpointValue(props.spaceBetween || { base: 10 });

	return (
		<>
			<CSwiper
				className={`${props.className} mySwiper2`}
				sx={style}
				w={'100%'}
				modules={[Navigation, Pagination, Thumbs, FreeMode]}
				navigation={
					props.navigation !== 'none' &&
					(props.navigation === 'navigation' ||
						typeof props.navigation === 'undefined')
				}
				pagination={props.navigation === 'pagination'}
				thumbs={props.withThumbs ? { swiper: thumbsSwiper } : undefined}
				slidesPerView={!props.withThumbs ? slidesPerView : 1}
				spaceBetween={spaceBetween}
				h={props.withThumbs ? '80%' : '100%'}
				freeMode={props.freeMode || false}
			>
				{getSlides()}
			</CSwiper>
			{props.withThumbs && (
				<CSwiper
					className="mySwiper"
					sx={style}
					modules={[Navigation, FreeMode, Thumbs]}
					slidesPerView={slidesPerView}
					spaceBetween={10}
					freeMode={true}
					watchSlidesProgress={true}
					onSwiper={setThumbsSwiper}
					h={'18%'}
				>
					{getSlides()}
				</CSwiper>
			)}
		</>
	);
}

export default chakra(Carousel);
