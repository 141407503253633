import { Capacitor } from '@capacitor/core';
import {
	AspectRatio,
	Box,
	Button,
	Fade,
	Flex,
	Heading,
	Image,
	Link,
	LinkBox,
	LinkOverlay,
	Skeleton,
	Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import NextLink from 'next/link';
import CardProduct from '../components/CardProduct';
import CardUser from '../components/CardUser';
import SectionHero from '../components/SectionHero';
import useAuth from '../hooks/useAuth';
import { IProduct } from '../services/api/product/types/IResponse';
import { IReviewStats, IUser } from '../services/api/user/types/IResponse';
import Carousel from '../ui/Carousel';
import Section from '../ui/Section';
import API from '../utils/api';
import { shuffleArray } from '../utils/array';
import baseCategories from '../utils/baseCategories';
import { advProfileEntry } from '../utils/defaultEntries';

const Home = ({
	products,
	users,
	ratings,
}: {
	products: IProduct[];
	users: IUser[];
	ratings: IReviewStats[];
}) => {
	const numSlides = 6;
	const numberOfProducts =
		Number(process.env.NEXT_PUBLIC_NUMBER_PRODUCTS_HOME) || 50;
	const numberUsers =
		Number(process.env.NEXT_PUBLIC_NUMBER_PROFILE_HOME) || 50;

	const { isAuth, user } = useAuth();

	const categories = baseCategories;

	const featuredProducts = products;
	const featuredUsers = users;

	return (
		<>
			<SectionHero />
			<Section
				title="Categorie in evidenza"
				my={{ base: 3, sm: 4, md: 8 }}
				titleProps={{ my: { base: 3, sm: 4, md: 8 } }}
			>
				{
					<Carousel
						maxSlides={categories?.length}
						my={8}
						spaceBetween={{ base: 17, sm: 35, md: 45, lg: 65 }}
						slidesPerView={{ base: 4, lg: 5, xl: 6 }}
						freeMode
					>
						{categories
							?.sort((a, b) =>
								a.metadata.homepageOrder >
								b.metadata.homepageOrder
									? 0
									: -1,
							)
							.map(category => (
								<Fade
									transition={{
										enter: { duration: 0.8 },
									}}
									in={categories.length > 0}
									key={category.id}
								>
									<NextLink
										passHref
										href={`/category/${category.id}`}
									>
										<Link color={'black'}>
											<Box textAlign={'center'}>
												<AspectRatio ratio={1} mb={3}>
													<Image
														borderRadius={'full'}
														alt={'category'}
														src={
															category?.metadata
																.image ||
															'http://placeimg.com/800/800/any'
														}
														fallback={
															<Skeleton
																borderRadius={
																	'full'
																}
															></Skeleton>
														}
														loading={'lazy'}
														maxHeight={'200px'}
													></Image>
												</AspectRatio>
												<Heading
													noOfLines={1}
													fontSize={{
														base: 'xs',
														sm: 'sm',
														md: 'md',
													}}
													fontWeight={700}
													pt={[0, 2]}
												>
													{category.name.includes(
														'Allattamento',
													)
														? 'Allattamento'
														: category.name}
												</Heading>
											</Box>
										</Link>
									</NextLink>
								</Fade>
							))}
					</Carousel>
				}

				{categories?.length === 0 && (
					<Carousel
						maxSlides={6}
						spaceBetween={{ md: 12, lg: 30 }}
						my={8}
						slidesPerView={{ base: 3.4, md: 4.4, lg: 6 }}
					>
						{Array.from(Array(6).keys()).map((key: number) => {
							return (
								<Box textAlign={'center'} key={key}>
									<AspectRatio ratio={1} mb={3}>
										<Skeleton
											borderRadius={'full'}
											h={'180px'}
										></Skeleton>
									</AspectRatio>
								</Box>
							);
						})}
					</Carousel>
				)}
			</Section>

			<Section title="In vetrina" mt={{ base: 2, sm: 4, md: 8 }}>
				{featuredProducts && (
					<Carousel
						maxSlides={
							featuredProducts.length < numberOfProducts
								? featuredProducts.length
								: numberOfProducts
						}
						slidesPerView={{ base: 2.4, sm: 3, md: 4, lg: 5 }}
						spaceBetween={{ base: 8, md: 12, lg: 15 }}
						freeMode
					>
						{featuredProducts
							.slice(0, numberOfProducts)
							// .filter(
							// 	prod =>
							// 		!prod.customAttributes?.sponsored ||
							// 		!prod.platformData?.adv?.find(
							// 			ad => ad.placement === 'home',
							// 		)?.active,
							// )
							.map((product: IProduct) => {
								return (
									<Fade
										transition={{
											enter: { duration: 0.8 },
										}}
										in={!!product.id}
										key={product.id}
									>
										<CardProduct
											product={product}
											heightImage={{
												base: '230px',
												sm: '280px',
												md: '300px',
												lg: '350px',
											}}
											placement={'home'}
										></CardProduct>
									</Fade>
								);
							})}
					</Carousel>
				)}
				{!featuredProducts && (
					<Flex minH={'150px'} align={'center'}>
						<Text fontWeight={600} color={'blackAlpha.500'}>
							Nessun articolo ancora disponibile
						</Text>
					</Flex>
				)}
			</Section>

			{isAuth ? (
				<Section
					title={'Sponsorizza i tuoi prodotti'}
					titleColor={'brandTwo.500'}
					borderless
					borderlessPadding={'1rem'}
					image={
						<Image
							alt={'Mockup app'}
							src={'/images/home_sponsorizza.webp'}
							h={{ base: '360px', sm: '380px', lg: '450px' }}
							w={'100%'}
							objectFit={'cover'}
							objectPosition={'center center'}
							loading={'lazy'}
						></Image>
					}
					imageOrientation="left"
				>
					<>
						<Text>
							Se vuoi vendere i tuoi prodotti molto più
							velocemente, puoi sponsorizzare i tuoi articoli e
							raggiungere molte più persone che stanno proprio
							cercando quello che hai nel tuo armadio!
						</Text>
						<Text mt={4}>
							Visiona tutte le possibili opzioni, potrai
							sponsorizzare i singoli prodotti, oppure il tuo
							intero profilo! Scopri tutte le opzioni cliccando
							sul pulsante qui sotto.
						</Text>
						<Box
							display={{ base: 'flex', md: 'block' }}
							justifyContent={'center'}
							w={'100%'}
						>
							<NextLink passHref href={'/adv'}>
								<Link>
									<Button
										size={'lg'}
										fontSize={{
											base: '2xl',
											md: undefined,
										}}
										mt={10}
									>
										Sponsorizza ora
									</Button>
								</Link>
							</NextLink>
						</Box>
					</>
				</Section>
			) : (
				<Section
					title={'Su di noi'}
					titleColor={'brandTwo.500'}
					borderless
					borderlessPadding={'1rem'}
					image={
						<Image
							alt={'Mockup app'}
							src={'/images/home_su_di_noi.webp'}
							w={'100%'}
							objectFit={'cover'}
							objectPosition={'top center'}
							h={{ base: '360px', sm: '380px', lg: '450px' }}
							loading={'lazy'}
						></Image>
					}
					imageOrientation="left"
				>
					<>
						<Text>
							BABYWANTED è l’app che piace ai bambini, nasce con
							l’idea di creare uno spazio dedicato solo a loro e
							alla loro community.
						</Text>
						<Text mt={4}>
							Canale di connessione tra le famiglie e piattaforma
							in cui condividere esperienze legate ai vostri
							piccoli, acquistando e vendendo vi aiuteremo a farli
							crescere.
						</Text>
						<Box
							display={{ base: 'flex', md: 'block' }}
							justifyContent={'center'}
							w={'100%'}
						>
							<NextLink passHref href={'/about-us'}>
								<Button
									size={'lg'}
									fontSize={{ base: '2xl', md: undefined }}
									mt={10}
								>
									Scopri di più
								</Button>
							</NextLink>
						</Box>
					</>
				</Section>
			)}

			{isAuth ? (
				<Section
					title={'I consigli della psicologa'}
					titleColor={'brandOne.500'}
					borderless
					borderlessPadding={'1rem'}
					image={
						<Image
							alt={'Mockup app'}
							src={'/images/home_psicologa.webp'}
							h={{ base: '360px', md: '380px', lg: '450px' }}
							w={'100%'}
							objectFit={'cover'}
							objectPosition={'center center'}
							loading={'lazy'}
						></Image>
					}
					imageOrientation="right"
				>
					<Text>
						<b>BABYWANTED </b>è più di un mercato. La nostra
						community viene prima di tutto. Ecco perché all’interno
						del nostro sito e della nostra app troverai lo spazio
						PARLA CON ME per chi avesse bisogno di confrontarsi con
						la nostra psicologa.
					</Text>
					<Box
						display={{ base: 'flex', md: 'block' }}
						justifyContent={'center'}
						w={'100%'}
					>
						<NextLink passHref href={'/helpline'}>
							<Link>
								<Button
									size={'lg'}
									fontSize={{ base: '2xl', md: undefined }}
									mt={10}
								>
									Parla con la psicologa
								</Button>
							</Link>
						</NextLink>
					</Box>
				</Section>
			) : (
				<Section
					title={'BABYWANTED per i professionisti'}
					titleColor={'brandOne.500'}
					borderless
					borderlessPadding={'1rem'}
					image={
						<Image
							alt={'Mockup app'}
							src={'/images/home_pro.webp'}
							h={{ base: '360px', md: '380px', lg: '450px' }}
							w={'100%'}
							objectFit={'cover'}
							objectPosition={'top center'}
							loading={'lazy'}
						></Image>
					}
					imageOrientation="right"
				>
					<Text>
						Hai un’attività e vuoi vendere su BABYWANTED? Iscriviti
						subito come Utente PRO!
					</Text>
					<Text mt={4}>
						Diventando un venditore PRO potrai ricreare la tua
						vetrina sul nostro marketplace moltiplicando la tua
						visibilità.
					</Text>

					<Box
						display={{ base: 'flex', md: 'block' }}
						justifyContent={'center'}
						w={'100%'}
					>
						<NextLink passHref href={'/lp/pro'}>
							<Link>
								<Button
									size={'lg'}
									fontSize={{
										base: '2xl',
										md: undefined,
									}}
									mt={10}
								>
									Diventa venditore PRO
								</Button>
							</Link>
						</NextLink>
					</Box>
				</Section>
			)}

			{!Capacitor.isNativePlatform() && (
				<Section
					title={'BABYWANTED sempre con te!'}
					titleColor={'brandTwo.500'}
					borderless
					borderlessPadding={'1rem'}
					image={
						<Image
							alt={'Mockup app'}
							src={'/images/home_mockup_app.webp'}
							h={{ base: '360px', md: '380px', lg: '450px' }}
							loading={'lazy'}
						></Image>
					}
					imageOrientation="left"
					className="no-app"
				>
					<Text w={'100%'}>
						Scarica gratuitamente l’app BABYWANTED. Esplora milioni
						di articoli unici, cerca tra migliaia di brand e trova i
						tuoi preferiti.
					</Text>
					<Text mt={4} w={'100%'}>
						Richiedi informazioni sull’articolo e acquistalo con un
						solo click. Paga in tutta sicurezza tramite BABYWANTED
					</Text>

					<Flex
						justifyContent={'space-around'}
						w={'100%'}
						alignItems={'end'}
						mt={6}
					>
						<LinkBox>
							<NextLink
								href={
									process.env.NEXT_PUBLIC_GOOGLE_APP_LINK ||
									'https://play.google.com/store/apps/details?id=com.babywanted.babywanted'
								}
								passHref
								target={'_blank'}
							>
								<LinkOverlay>
									<Image
										src="/svg/google-play-badge.svg"
										alt="Scarica su Google Play Store"
										fit={'contain'}
										h={{ base: '40px', xl: '50px' }}
									/>
								</LinkOverlay>
							</NextLink>
						</LinkBox>
						{/* <LinkBox>
							<NextLink
								href={
									process.env.NEXT_PUBLIC_GOOGLE_APP_LINK ||
									'https://play.google.com/store/apps/details?id=com.babywanted.babywanted'
								}
								passHref
								target={'_blank'}
							>
								<LinkOverlay> */}
						<LinkBox>
							<NextLink
								href={
									process.env.NEXT_PUBLIC_APPLE_APP_LINK ||
									'https://apps.apple.com/it/app/babywanted/id6472043668?l=en-GB'
								}
								passHref
								target={'_blank'}
							>
								<LinkOverlay>
									<Image
										src="/svg/apple-store-badge.svg"
										alt="Scarica su Apple Store"
										fit={'contain'}
										h={{ base: '40px', xl: '50px' }}
									/>
								</LinkOverlay>
							</NextLink>
						</LinkBox>
						{/* </LinkOverlay>
							</NextLink>
						</LinkBox> */}
					</Flex>
				</Section>
			)}

			<Section
				title={'La nostra community'}
				color={'brandOne.500'}
				mb={{ base: 0, sm: 4, md: 8 }}
			>
				<Carousel
					maxSlides={numberUsers}
					spaceBetween={{ base: 17 }}
					slidesPerView={{ base: 3, sm: 4, md: 5, lg: 6 }}
					mt={6}
					freeMode
				>
					{featuredUsers
						.slice(0, numberUsers)
						.filter(
							usr =>
								usr.id !== user?.id &&
								!usr.displayName.includes('admin_'),
						)
						.map(usr => (
							<Fade
								transition={{
									enter: { duration: 0.8 },
								}}
								in={!!usr.id}
								key={usr.id}
							>
								<CardUser
									color={'black'}
									user={usr}
									starSize={{ base: 3, md: 5 }}
									withoutRatingsNumber
									rating={{
										count:
											ratings.find(
												rating =>
													rating.targetId === usr.id,
											)?.count || 0,
										avg:
											ratings.find(
												rating =>
													rating.targetId === usr.id,
											)?.avg || 0,
									}}
									heightImage={'200px'}
									textAlign={'center'}
								/>
							</Fade>
						))}
				</Carousel>
				<Heading
					mt={{ base: 12, lg: 20 }}
					mb={{ base: 0, lg: 6 }}
					fontWeight={'bold'}
					textAlign={'center'}
					color={'black'}
				>
					Entra anche tu a far parte della community!
				</Heading>
			</Section>
		</>
	);
};

export async function getServerSideProps({ req }: { req: any }) {
	const numberOfProducts =
		Number(process.env.NEXT_PUBLIC_NUMBER_PRODUCTS_HOME) || 15;
	const numberUsers =
		Number(process.env.NEXT_PUBLIC_NUMBER_PRODUCTS_HOME) || 15;
	const userId =
		typeof req.cookies['bw-tkn'] !== 'undefined'
			? JSON.parse(req.cookies['bw-tkn']).userId
			: undefined;

	async function getProducts() {
		return API.Shop.search(
			{ limit: numberOfProducts, cursor: 1 },
			{
				validated: true,
				sort: [{ sponsoredHome: 'desc' }, { _createdDate: 'desc' }],
				filter: userId ? `NOT (_ownerId == ${userId})` : undefined,
			},
		);
	}

	async function getUsers() {
		const entryAdvProfile = await API.Shop.getEntry(advProfileEntry);
		const userIds: string[] = entryAdvProfile.fields.home
			? shuffleArray(entryAdvProfile.fields.home as string[]).slice(
					0,
					numberUsers,
			  )
			: [];
		let otherUsers;
		let usersPromises = [
			API.User.listUsers(
				{
					cursor: 1,
					limit: userIds.length > 100 ? 100 : userIds.length || 1,
				},
				{
					id: userIds,
				},
			),
		];

		if (userIds.length < numberUsers) {
			otherUsers = API.User.listUsers({
				limit: numberUsers - userIds.length,
				cursor: 1,
			})!;
			usersPromises.push(otherUsers);
		}

		return Promise.all(usersPromises);
	}

	const results = await Promise.all([getProducts(), getUsers()]);

	const targetIds = [
		...new Set(
			results[1]
				.map(el => el?.items)
				.flat(1)
				.map(el => el?.id),
		),
	];

	const ratings =
		(
			await API.User.getReviewStats(
				{ cursor: 1, limit: numberUsers },
				{
					groupBy: 'targetId',
					targetId: targetIds as string[],
				},
			)
		)?.items || [];

	// Pass data to the page via props
	return {
		props: {
			products: results[0]?.items.filter(el => el) || null,
			users: _.uniqBy(
				results[1]
					.filter(el => el)
					.map(el => el?.items)
					.flat(1),
				_.property('id'),
			),
			ratings: ratings.filter(el => el) || null,
		},
	};
}

export default Home;
