import {
	AspectRatio,
	Box,
	chakra,
	ChakraProps,
	Flex,
	Icon,
	Image as Img,
	LinkBox,
	LinkOverlay,
	Skeleton,
	Spacer,
	Tag,
	TagLabel,
	Image,
	Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import Rating from 'react-rating';
import { IUser } from '../../services/api/user/types/IResponse';
import IBaseProps from '../../types/interfaces/IBaseProps';
import Absolute from '../../ui/Absolute';
import API from '../../utils/api';
import optimizeImg from '../../utils/images';

interface IProps extends IBaseProps {
	userId?: string;
	user?: IUser;
	rating?: {
		avg: number;
		count: number;
	};
	widthImage?: string | number;
	heightImage?: string | number;
	starSize?: ChakraProps['boxSize'];
	withoutRatingsNumber?: boolean;
}

function CardUser(props: IProps) {
	const {
		data: userReq,
		isLoading: userInfoIsLoading,
		error: userInfoError,
	} = useQuery(
		['user', props.userId],
		async () => await API.User.get(props.userId!),
		{
			enabled: !!props.userId,
			retry: false,
		},
	);

	const reviewStatsReq = useQuery(
		['reviews', props.userId],
		async () => {
			return await API.User.getReviewStats(
				{ cursor: 1, limit: 1 },
				{
					targetId: props.userId,
					groupBy: 'targetId',
					label: 'transactionRating:standard',
				},
			);
		},
		{
			enabled: !props.rating && !!props.userId,
		},
	);

	const userInfo = props.user || userReq;
	const reviewStats = props.rating || reviewStatsReq.data?.items[0];

	return (
		<Flex direction={'column'} className={props.className}>
			<LinkBox
				h={'100%'}
				display="flex"
				alignItems={'center'}
				justifyContent={'center'}
				pos={'relative'}
			>
				<NextLink href={`/user/${userInfo?.id}`} passHref>
					<LinkOverlay
						w={'100%'}
						fontWeight={'bold'}
						color={'blackAlpha.700'}
						maxW={'150px'}
						_hover={{ bg: 'gray.50', borderRadius: 'full' }}
					>
						<AspectRatio ratio={1}>
							<Img
								borderRadius={'full'}
								w={props.widthImage || '100%'}
								h={props.heightImage || 'auto'}
								objectFit={'cover'}
								src={
									userInfo?.metadata?.imgProfile
										? optimizeImg(
												userInfo?.metadata?.imgProfile,
												{
													height: props.heightImage
														? typeof props.heightImage ===
														  'string'
															? parseInt(
																	props.heightImage,
															  )
															: props.heightImage
														: undefined,
													width: props.widthImage
														? typeof props.widthImage ===
														  'string'
															? parseInt(
																	props.widthImage,
															  )
															: props.widthImage
														: undefined,
													quality: 85,
													compressionLevel: 3,
													output: 'webp',
												},
										  )
										: `https://ui-avatars.com/api/?name=${userInfo?.displayName}`
								}
								fallback={
									<Skeleton
										borderRadius={'full'}
										height={props.heightImage}
									></Skeleton>
								}
								loading={'lazy'}
							></Img>
						</AspectRatio>
					</LinkOverlay>
				</NextLink>
				{userInfo?.platformData?.specialUser && (
					<Absolute
						top={'unset'}
						left={'unset'}
						right={{ base: 0, xl: '20px' }}
					>
						<Image
							alt={'badge-special'}
							src={'/images/badge_special_user.png'}
							maxH={'40px'}
							ml={3}
						/>
					</Absolute>
				)}
			</LinkBox>

			<Box mt={4} mx={2} lineHeight={1.3}>
				<Box textAlign={'center'}>
					<Flex id="number_wishlist" justify={'center'}>
						<Text fontWeight={700} fontSize={'md'} noOfLines={1}>
							{userInfo?.displayName}
						</Text>
					</Flex>
					{userInfo?.platformData?.userType === 'business' && (
						<>
							<Spacer />
							<Tag
								my={2}
								color={'black'}
								colorScheme={'brandOne'}
							>
								Pro
							</Tag>
						</>
					)}
				</Box>
				<Box color={'gray.400'} fontSize={'smaller'}>
					{reviewStats && reviewStats?.count > 0 && (
						<Flex
							align={'center'}
							//h={sizes?.heightRow || undefined}
							fontWeight={500}
							color={'blackAlpha.600'}
							fontSize={'md'}
							//mt={1}
							flexGrow={1}
							mt={1}
							justify={'center'}
						>
							<Flex h={'100%'} align={'center'} lineHeight={1}>
								<Rating
									initialRating={reviewStats.avg / 20}
									emptySymbol={
										<Icon
											boxSize={props.starSize || 5}
											as={BsStar}
											color={'brandOne.500'}
											mr={1}
										/>
									}
									fullSymbol={
										<Icon
											as={BsStarFill}
											boxSize={props.starSize || 5}
											color={'brandOne.600'}
											mr={1}
										/>
									}
									readonly
								></Rating>
							</Flex>
							{!props.withoutRatingsNumber && (
								<Flex h={'100%'} align={'center'} ml={2}>
									({reviewStats.count})
								</Flex>
							)}
						</Flex>
					)}
				</Box>
				{userInfo?.platformData?.adv?.active && (
					<Box
						textAlign={'center'}
						color={'gray.400'}
						fontSize={'smaller'}
						mt={2}
					>
						<Tag
							size={'sm'}
							variant={'subtle'}
							bgColor={'gray.200'}
							color={'blackAlpha.700'}
							shadow={'sm'}
							py={1}
							px={2}
							alignItems={'center'}
						>
							<Flex align={'end'}>
								<Img
									src={'/images/icon_logo_gray.webp'}
									h={'16px'}
									display={{ base: 'none', md: 'block' }}
								/>
								{/* <TagLeftIcon as={InfoIcon} /> */}
								<TagLabel
									ml={{ base: 0, md: 1 }}
									mb={{ base: 0, md: '1px' }}
								>
									Sponsorizzato
								</TagLabel>
							</Flex>
						</Tag>
					</Box>
				)}
			</Box>
		</Flex>
	);
}

export default chakra(CardUser);
