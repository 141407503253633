import {
	Box,
	Button,
	chakra,
	Flex,
	Heading,
	Image,
	Link,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import useAuth from '../../hooks/useAuth';
import IBaseProps from '../../types/interfaces/IBaseProps';

function SectionHero(props: IBaseProps) {
	const responsiveImageSrc = useBreakpointValue({
		base: '/images/home_new.webp',
		// md: 'https://picsum.photos/450/400',
	});

	function getTitleHero(text: string) {
		return (
			<Heading fontWeight={'black'} fontSize={'5xl'} my={12}>
				{text}
			</Heading>
		);
	}

	const { isAuth } = useAuth();

	return (
		<Box
			className={props.className}
			bgImage={'/images/background_home_section_old.webp'}
			bgPosition={'center'}
			mb={{ base: 10, md: 15, lg: 20 }}
		>
			<Flex
				direction={'row'}
				justifyContent="space-between"
				alignItems={'center'}
				wrap={['wrap', null, null, 'nowrap']}
				mx={{ base: 0, lg: 20, xl: 28 }}
				h={'100%'}
			>
				{/* <AspectRatio
					ratio={1}
					w={{ base: '100%', md: '40%' }}
					minW={{ base: 0, md: '300px', xl: '500px' }}
					alignSelf={'start'}
				> */}
				<Flex
					justify={'center'}
					maxW={{ base: '100%', md: '40%' }}
					ml={{ base: 0, lg: 8, xl: 28 }}
					order={{ base: 2, md: 'unset' }}
				>
					<Image
						src={responsiveImageSrc}
						verticalAlign={'top'}
						alt={'hero-image'}
						objectFit={'cover'}
						maxH={{ base: '100%', md: '40%' }}
						h={'100%'}
					/>
				</Flex>
				{/* </AspectRatio> */}
				<Box
					// bgGradient={[
					// 	'linear-gradient(to-b, rgba(255,255,255,0) 70%,rgba(255,255,255,1)), linear-gradient(45deg, brandOne.500 38%, brandTwo.500 62%)',
					// 	null,
					// 	'unset',
					// ]}
					pb={16}
					pl={{ base: 0, lg: 18, xl: 28 }}
					pr={{ md: 8, xl: 16 }}
					w={{ md: '50%' }}
				>
					<Heading
						fontWeight={'black'}
						fontSize={'3xl'}
						mt={12}
						mb={{ base: 8, md: 12 }}
					>
						<Box
							display={{ md: 'none' }}
							color={'black'}
							mx={8}
							textAlign={'center'}
							lineHeight={1.2}
						>
							<Text
								mb={4}
							>{`SECONDA VITA, NUOVE EMOZIONI.`}</Text>
							<Text fontWeight={600}>
								{`Vendi e acquista articoli di seconda mano:
								risparmi e crei un domani più sostenibile per i tuoi figli.`}
							</Text>
						</Box>
						<Box display={{ base: 'none', md: 'block' }}>
							<Text
								mb={2}
							>{`SECONDA VITA, NUOVE EMOZIONI.`}</Text>
							<Text fontWeight={600}>
								{`Vendi e acquista articoli di seconda mano:
								risparmi e crei un domani più sostenibile per i tuoi figli.`}
							</Text>
						</Box>
					</Heading>
					<Flex justify={{ base: 'center', md: 'left' }}>
						{/* <Image
							src="/svg/google-play-badge.svg"
							alt="Scarica su Google Play Store"
							fit={'contain'}
							h={{ base: '40px', xl: '50px' }}
						/>
						<Image
							src="/svg/apple-store-badge.svg"
							alt="Scarica su Google Play Store"
							h={{ base: '40px', xl: '50px' }}
							ml={12}
						/> */}
						{isAuth ? (
							<NextLink href={'/product/new'} passHref>
								<Link>
									<Button
										size={'lg'}
										fontSize={'2xl'}
										boxShadow={'md'}
									>
										Vendi ora
									</Button>
								</Link>
							</NextLink>
						) : (
							<NextLink href={'/lp/sell'} passHref>
								<Link>
									<Button
										size={'lg'}
										fontSize={'2xl'}
										boxShadow={'md'}
									>
										Inizia a vendere ora
									</Button>
								</Link>
							</NextLink>
						)}
					</Flex>
				</Box>
			</Flex>
		</Box>
	);
}

export default chakra(SectionHero);
