const baseCategories = [
	{
		id: 'ctgy_AY5dRe1ltt1pP7IUltt',
		createdDate: '2022-09-27T16:26:15.974Z',
		updatedDate: '2023-02-19T11:59:11.089Z',
		name: 'Abbigliamento',
		parentId: null,
		metadata: {
			image: '/images/categories/ABBIGLIAMENTO.webp',
			homepageOrder: 1,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_fYW3He1XTI1pAh66XTI',
		createdDate: '2022-09-27T16:51:41.965Z',
		updatedDate: '2023-02-19T11:59:14.313Z',
		name: 'Allattamento e pappa',
		parentId: null,
		metadata: {
			image: '/images/categories/ALLATTAMENTO_PAPPA.webp',
			homepageOrder: 5,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_OZwaTe1mSK1pPgDMmSK',
		createdDate: '2022-09-27T16:57:08.262Z',
		updatedDate: '2023-02-19T17:10:07.170Z',
		name: 'Altri articoli',
		parentId: null,
		metadata: {
			image: '/images/categories/ALTRO.webp',
			homepageOrder: 8,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_0dPF0e1cT01pFgsvcT0',
		createdDate: '2022-09-27T16:56:41.203Z',
		updatedDate: '2023-02-19T11:59:15.267Z',
		name: 'Asilo e scuola',
		parentId: null,
		metadata: {
			image: '/images/categories/SCUOLA.webp',
			homepageOrder: 7,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_lo9SBe1OcR1p1q0pOcR',
		createdDate: '2022-09-27T16:36:56.877Z',
		updatedDate: '2023-02-19T11:59:14.425Z',
		name: 'Casa',
		parentId: null,
		metadata: {
			image: '/images/categories/CASA.webp',
			homepageOrder: 3,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_N00afe1c1v1pFFerc1v',
		createdDate: '2022-09-27T16:47:19.459Z',
		updatedDate: '2023-02-19T11:59:14.512Z',
		name: 'Giochi',
		parentId: null,
		metadata: {
			image: '/images/categories/GIOCHI.webp',
			homepageOrder: 2,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_n0pBBe1T2L1p6G38T2L',
		createdDate: '2022-09-27T16:45:32.431Z',
		updatedDate: '2023-02-19T11:59:14.610Z',
		name: 'Igiene e cura',
		parentId: null,
		metadata: {
			image: '/images/categories/IGIENE_CURA.webp',
			homepageOrder: 6,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
	{
		id: 'ctgy_aYSY2e1lGk1pOUOblGk',
		createdDate: '2022-09-27T16:41:53.205Z',
		updatedDate: '2023-02-19T11:59:15.968Z',
		name: 'Viaggio',
		parentId: null,
		metadata: {
			image: '/images/categories/VIAGGIO.webp',
			homepageOrder: 4,
			categoryHierarchy: [Object],
		},
		platformData: {},
		livemode: false,
	},
];

export default baseCategories;
